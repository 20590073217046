.openModal-div {
    cursor:pointer;
    color:#007bff;
    text-decoration:none;
}
.openModal-div:hover {
    text-decoration:underline;
    /* color:#0056b3; */
    text-shadow: 0.1px 0.1px 0.1px #555;
}
.personmodal-background {
    display: block;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
  }
  .personmodal-content {
    background-color: #fefefe;
/*  margin-left: auto;
    margin-right: auto; */
    border: 1px solid #888;
    margin-top: 5%;
    
  }
  .personmodal-body{
      height:auto;
  }
