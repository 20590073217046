.objectContent{
    border-style: solid;
    border-color:  #0747a6; 
    border-width: thin;
    border-radius: 4px;
    /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
    background-color: rgb(246, 246, 242);
    padding: 20px;
}
.search-result-number{
    width: 75px!important;
}
.search-button{
     min-width: 150px; 
}

.show{
    display: block;
}
.hide{
    display: none;
}