.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #000000;
  background-color: transparent;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 6px 12px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.react-autosuggest__input:focus {
  outline: none;
  border-color: #66afe9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container--open {
  position: absolute;
  top: 34px;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 14px;
  z-index: 999;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 6px 12px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--focused {
  background-color: #0c7eaf;
  color: #fff;
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  width: 100%;
}

.highlight {
  color: #ee0000;
}

.react-autosuggest__suggestion--focused .highlight {
  color: #120000;
}

.Select-menu-outer {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 3;
  -webkit-overflow-scrolling: touch;
}
