nav.fa {
  font-size: 1em;
  line-height: 20px;
}

.brand {
  position: absolute;
  top: 5px;
  left: 5px;
  display: inline-block;

  width: 20px;
  height: 20px;
  background-size: 80%;
  margin: 0 10px 0 0;
  border-radius: 10px;
}

.appContent {
  margin-bottom: 50px;
  padding-bottom: 35px;
}

.footer {
  text-align: right;
  bottom: 0;
  width: 100%;
}

.version {
  font-size: smaller;
  color: lightgray;
}

.version a {
  font-size: smaller;
  text-decoration: none;
  color: lightgray !important;
}

.backgroundUTV {
  height: 50px;
  background-color: #ff6666;
  font-size: smaller;
  color: black;
  margin-bottom: 1;
  padding-right: 10px;
}

.backgroundTEST {
  height: 50px;
  background-color: orange;
  font-size: smaller;
  color: black;
  margin-bottom: 1;
  padding-right: 10px;
}
.footer.well {
  margin-bottom: 0;
}
