body {
  margin: 0;
  padding: 0;
}

label {
  font-weight: bolder;
}

.icon {
  width: 1%;
}

.navbar-brand {
  position: relative;
  padding-left: 50px;
}

.childObject {
  color: darkgray;
}

.btn-light {
  color: #212529;
  background-color: #dcdcdc;
}

a:link {
  color: #337ab7;
}
a:visited {
  color: #337ab7;
}

.form-control::-ms-clear {
  display: none;
}

.table tr {
  height: 40px;
}

.page-header {
  margin: 15px 0 15px;
}
