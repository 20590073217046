.welcomePanel {
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    margin-top: 20px;
}

.welcomePanel > div {
    width: 700px;
    border: 1px solid;
    padding: 50px 20px;
}

.title {
    margin-top: 50px;
    font-size: 24px;
}

.buttonText {
  font-size: 20px;
}

.moreInfo {
  font-size: 14px !important;
}

img[alt="feide-logo-button"] {
    max-height: 2em;
}
