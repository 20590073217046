.labelText {
    font-size: 0.85em;
}
.pickListIcon {
    display: flex;
    flex-direction: row;
}
.label {
    width: 100%;
}
.select {
    width: 1%;
}
.selectNopadding {
    margin: 0px;
}
.toolsColumn {
    vertical-align: bottom;
    text-align: right;
}
.actions {
    width: 18%;
    text-align: right;
}
.actionList {
    max-width: 400px;
    margin: 0 auto 10px;
}
.normalAction {
    padding-left: 1em;
    cursor: pointer;
    vertical-align: middle;
    color: #337ab7;
}
.normalActionNoPadding {
    cursor: pointer;
    vertical-align: middle;
    color: #337ab7;
}
.infoAction {
    color: #0000aa;
    font-size: 1.5em;
    padding-left: 0.5em;
}