.row {
  padding-top:10px;
}

.collapsing {
  transition: none !important;
}

.deleteIcon:hover {
  color: red
}
.invalidForm{ 
  border: 2px solid red!important;
}