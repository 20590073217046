  #searchInput {
    border: 1px solid #999;
    padding: 0.5rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0px;
  }

  .input-group-addon {
    padding: 8px 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 0px;
    vertical-align: middle;
  }
  
  .input-group >.fa-search{
    display: table-cell;
  }

  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }
  
  .suggestions {
    position: relative;
    border: 1px solid #999;
    border-top-width: 1;
    list-style: none;
    margin-top: 1px;
    overflow-y: auto;
    padding-left: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  
  .suggestions li {
    padding: 0.5rem;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color: white;
    color: black;
    cursor: pointer;
    font-weight: 700;
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }
  .shadow{
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }