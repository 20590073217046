th {
  cursor: pointer;
}

th.reactable-header-sort-desc, th.reactable-header-sort-asc {
  color: white;
  background-color: #474747;
}

th.reactable-header-sort-desc:after {
  content: '\25B2';
  font-size: 10px;
  padding-left: 10px;
  padding-bottom: 5px;
}

th.reactable-header-sort-asc:after {
  content: '\25BC';
  font-size: 10px;
  padding-left: 10px;
  padding-bottom: 5px;
}

tr.reactable-filterer {
  width: 100%;
}

tr.reactable-filterer td {
  width: 100%;
}

