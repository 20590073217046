
.buttonpaddingtrue {
    margin-left: 5px;
    margin-right: 5px;
    background-color: #bfbfbf
}
.buttonpaddingfalse {
    margin-left: 5px;
    margin-right: 5px;
}
.false {
  color: #cc0000;
}
.true {
  color: #00b300;
}
