.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.searchField {
  flex: 2 0 0;
}

.toolBarButtons {
  flex: 1 0 0 ;
  display: flex;
  flex-direction: row;
}