@font-face {
  font-family: 'untitled-font-1';
  src: url('untitled-font-1.eot');
  src: url('untitled-font-1.eot?#iefix') format('embedded-opentype'),
    url('untitled-font-1.woff') format('woff'),
    url('untitled-font-1.ttf') format('truetype'),
    url('untitled-font-1.svg#untitled-font-1') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'untitled-font-1' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'untitled-font-1' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-musitalcoholicon:before {
  content: '\61';
}
.icon-musitarcheologyicon:before {
  content: '\62';
}
.icon-musitbotanyicon:before {
  content: '\63';
}
.icon-musitmoldicon:before {
  content: '\64';
}
.icon-musitmovehistoryicon:before {
  content: '\65';
}
.icon-musitnumismaticsicon:before {
  content: '\66';
}
.icon-musitwaterdamageicon:before {
  content: '\67';
}
.icon-musitbuildingicon:before {
  content: '\68';
}
.icon-musitcleaningicon:before {
  content: '\69';
}
.icon-musitcontrolicon:before {
  content: '\6a';
}
.icon-musitcontrolobsicon:before {
  content: '\6b';
}
.icon-musitentomologyicon:before {
  content: '\6c';
}
.icon-musitethnographyicon:before {
  content: '\6d';
}
.icon-musitfireprotectionicon:before {
  content: '\6e';
}
.icon-musitgasicon:before {
  content: '\6f';
}
.icon-musithypoxicairicon:before {
  content: '\70';
}
.icon-musitlightingcondicon:before {
  content: '\71';
}
.icon-musitobservationicon:before {
  content: '\73';
}
.icon-musitperimetersecurityicon:before {
  content: '\74';
}
.icon-musitpesticon:before {
  content: '\75';
}
.icon-musitplusminusicon:before {
  content: '\78';
}
.icon-musitrelhumidityicon:before {
  content: '\79';
}
.icon-musittemperatureicon:before {
  content: '\7a';
}
.icon-musittheftprotectionicon:before {
  content: '\41';
}
.icon-musitobject:before {
  content: '\72';
}
.icon-musitpicklistnode:before {
  content: '\76';
}
.icon-musitpicklistobject:before {
  content: '\77';
}
.icon-musit-microscope:before {
  content: '\4a';
}
.icon-musit-testtube:before {
  content: '\4b';
}
