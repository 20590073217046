.objectContainer{
    background-color: #f6f6f2;
    border-style: solid;
    border-color:  #0747a6; 
    border-width: thin;
    border-radius: 4px;
    /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
    /* background-color: rgb(246, 246, 242); */
    padding: 20px;
}
.createLink {
    margin-top: 15px;
    cursor:pointer;
    color:#007bff;
    text-decoration:none;
}
.createLink:hover {
    text-decoration:underline;
    /* color:#0056b3; */
    text-shadow: 0.1px 0.1px 0.1px #555;
}
.show {
    display: block;
}
.hide {
    display: none;
}